// Single Lesson in the list
// It's used to wrap a single lesson that contains all information about its

.lessons-builder-lesson {
  position: relative;
  padding-top: 40px;

  // Add
  &-add {
    position: absolute;
    top: 0;
    left: 0;

    .float-button-text {
      opacity: 1;
      left: -2px;
      color: $text-muted;
    }
  }

  // Container
  &-container {
    border-radius: 5px;
    background-color: rgba($gray-200, .6);
    border: 1px solid $gray-200;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    padding-bottom: 50px;
    box-shadow: 0 2px 1px -1px rgba(58, 66, 80, 0.2), 
                0 1px 1px 0 rgba(58, 66, 80, 0.14);

    // Show Btn Add Task
    &:hover {
      .lessons-builder-task-add {
        opacity: 1;
      }
    }
  }

  // Header
  &-header {
    position: relative;
    display: flex;
    font-size: .92rem;

    .drag-drop-button {
      position: absolute;
      left: -21px;
      top: -3px;
    }

    // &-index {
    //   font-size: .9rem;
    // }
    
    // Title
    &-title {
      margin: .4rem 0 .5rem;
      // color: theme-color("primary");
      color: $body-color;
    }

    // Actions
    &-actions {
      margin-left: auto;
      display: flex;
    }

    // Options
    &-options {
      display: flex;
      //align-items: center;
      & > * {
        margin-right: 20px;
      }
    }

    // Btns
    &-btns {
      display: flex;
      .btn {
        margin-top: -7px;
        height: 40px;
      }
    }
  }

  // New Task
  &-new {
    position: relative;
    background: rgba($gray-300, 1);
    border-radius: 6px;
    border: 1px solid $border-color;
    margin-bottom: 23px;
    padding: 1.5rem; 
  }

  &-new {
    .editor-container {
      padding: 1.25rem;
    }
  }

  // New Content
  &-content-add {
    text-align: right;
    padding: .5rem 0;

    .btn-outline-primary {
      &:hover {
        background: #fff;
        color: $brand-secondary;
      }
    }
  }
  
}