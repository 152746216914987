.builder-new-task-content {

  // Btn Wrapper
  &-btn-wrapper {
    text-align: right;
    margin-bottom: .5rem;

  }

  // Btn Close
  &-btn-close {       
    display: inline-flex;
    align-items: center;
    color: $text-dark;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }

    i {
      margin-right: 5px;
      font-size: 1.5rem; 
    }
  }

  // Card Header
  .card-header {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin: 0;
    }
  }  

  // Editor
  &-editor {
    .nav-tabs {
      z-index: 1;
      position: relative;
      top: 1px;
    }
  }

}