.list-scroll {
  
  .list-group-item-action {
    &:not(.active) {
      color: $list-disabled-color;
    }

    &:hover {
      background: $white;
      color: $list-color;
    }
  }  

}