.lessons-builder-task-questions {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; 
  margin-top: 1rem 0 .5rem;

  // Item
  &-item {
    background: rgba($gray-200, .7);
    border-radius: 5px;
    margin: 3px 0;
    padding: 1rem 1rem 0 1rem;
    color: $body-color; 
    position: relative;

    .row {
      position: relative;
      @include media-breakpoint-up(md) {
        padding-right: 110px;
      } 
    }
    
  }

  // Btn Close
  &-btns {
    position: absolute;
    top: -6px;
    right: 1rem;    
    cursor: pointer;
    .btn {
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
  }

  // Info
  &-info {
    padding: 1rem 0;
  }

  // Content
  &-content {
    font-size: 90%;
    @include media-breakpoint-up(md) {
      //margin-left: 8.33333%;      
    }     
  }

  // New
  &-new {
    

    // Question
    &-question {
      background: rgba($gray-200, .7);
      border-radius: 5px;
      margin: 1rem 0;
      padding: 1rem 1rem 0 1rem;
      color: $body-color; 
      position: relative;
    }

    // Answers
    &-answers {
      margin: 1rem 0;
    }

    // Btns
    &-btns {
      text-align: right;
      margin: 1rem 0;
    }
  }

}