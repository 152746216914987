.card {
  margin-bottom: 1.5 * $spacer;
  -webkit-box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
  box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
  border-radius: 7px;

  // Cards with color accent
  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      border-color: darken($value, 12.5%);
      .card-header {
        //background-color: darken($value, 3%);
        border-color: darken($value, 12.5%);
      }
    }
  }

  .lead {
    font-size: $font-size-base;
  }

  .card-body {
    .mb-4.col-12 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.text-white .text-muted {
  color: rgba(255,255,255,.6) !important;
}

*****************************Lesson,content buttons ************************************************
  // body {
  //   background: linear-gradient(90deg, #FF8008 10%, #FFC837 90%);
  //   padding: 2em;
  //   text-align: center;
  // }
  
  // * {
  //   box-sizing: border-box;
  // }  
  
  
  
  /* DropDown Columns */
  div.dropdown-multicol2{
    width: 30em;
  }

  $box-width: 300px;
  $box-height: 212px;
  
  div.dropdown-multicol2>div.dropdown-col{
    display:inline-block;
    // width: 32%;
  }

  .dropdown-menu.dropdown-multicol2{
      position: relative;
      width: 450px;
  }
  
  /*
  Or just:
  div.dropdown-row>a.dropdown-item{
    display:inline-block;
    width: 10em;
  }
  
  (Former may be more convenient for responsive)
  */


  .dropdowm-plus{
      margin-top: 10px;
  }

  .dropdown-flex{
      display: flex
  }
.bg-color{
    background-color: #f4f9fb;
}
  .contentoptions-alignment{
      margin-left: 10px;
      margin-bottom: 10px;
  }
  .newcontent-alignment{
      margin-left: 10px;
  }
  
// Loading
.content-loading {
  width: 100%;
  min-height: 400px !important;
}
.card-height{
    min-height: 100px;
    padding: 2rem 0rem;
    // padding-left: 0px
}
.lession-padding{
    padding-left: 15px
}

.plus-circle-effect{
    margin-left: -44px;
    margin-top: 20x !important;
    color: white;
    text-shadow: 2px 0px 10px #747d8d;
}
.a-position{
    margin-left: 10px;
    margin-top: 20px;
    cursor: pointer;
}
.close-icon-color{
    color: #d98100 !important
}
.card-header {
  background: transparent;
//   padding: 2rem 0rem;

  &.has-badge {
    position: relative;

    .card-title {
      padding-right: 80px;
    }
    .badge {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .card-title {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .icon-bg {
    display: inline-body;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  .nav.nav-tabs {
    margin-top: -$card-spacer-y;
    margin-bottom: -$card-spacer-y;
    border-bottom: 0;

    .nav-item {
      border-top: 0;
    }

    .nav-link {
      padding: $card-spacer-y $card-spacer-x / 2;
      color: $text-light;
      border-top: 0;

      &.active {
        color: $text-color;
        background: white;
      }
    }
  }

  &.card-header-inverse {
    color: white;
  }

  .btn {
    margin-top: - $input-btn-padding-y;
  }
  .btn-sm {
    margin-top: - $input-btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: - $input-btn-padding-y-lg;
  }
}

.card-body {
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 0.75rem;
  }    
}

// Card Footer
.card-footer {
  border-radius: 0 0 7px 7px;
  background: none;
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }
  @include media-breakpoint-down(xs) {
    padding: 0.75rem;
  } 

  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;
    }
  }
}

[class*="card-outline-"] {
  .card-body {
    background: white !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Cards with color accent
@each $color, $value in $theme-colors {
  .card-accent-#{$color} {
    @include card-accent-variant($value);
  }
}

// Card Actions
.card-header {
  > i {
    margin-right: $spacer / 2;
  }
  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a, button {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $text-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      [class^="icon-"], [class*=" icon-"] {
        display: inline-body;
        vertical-align: middle;
      }

      i {
        display: inline-body;
        transition: .4s;
      }

      .r180 {
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: white;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-full {
  margin-top: - $spacer;
  margin-right: - $grid-gutter-width / 2;
  margin-left: - $grid-gutter-width / 2;
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}

@include media-breakpoint-up(sm) {
  .card-columns {

    &.cols-2 {
      column-count: 2;
    }
  }
}

.card {
  &.drag, .drag {
    cursor: move;
  }
}

.card-placeholder {
  background: rgba(0,0,0,.025);
  border: 1px dashed $gray-300;
}

// Card Grid
.card-grid {
  .card-header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  .card-img-top {
    max-width: 330px;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem !important;
  }

  .lastActivities {
    display: none;
    &.show {
      display: block;
    }
  }
}

// Card List
.card-list {  
  overflow: hidden;
  // &.card {
  //   box-shadow: none;
  // }

  .header-wrapper {
    @include media-breakpoint-up(md) {
      display: flex;
      flex: 0 0 2rem;
    }
  }

  .card-img-top {
    max-width: 330px;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem !important;
    @include media-breakpoint-up(md) {      
      max-width: 200px;
      margin: 0;
      margin-right: 4%;
      margin-bottom: 0 !important;
      width: 100px;
      height: 100px;      
    }
    @include media-breakpoint-up(lg) {
      width: 150px;
      height: 150px;
    }
    @include media-breakpoint-up(xl) {
      width: 200px;
      height: 200px;
    }
  }

  .lastActivities {
    display: none;
    &.show {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    .card-header {
      border-bottom: none !important;
      padding-bottom: 0 !important;      
    }
  }

  @include media-breakpoint-up(md) {
    display: flex !important;
    flex-direction: row !important;
    border-color: $border-color !important;

    .card-body,
    .card-footer {
      background: rgba($gray-100, .5);
      border-radius: 0;
    }

    hr {
      visibility: hidden;
      margin: 5px 0;
    }

    .lead {
      font-size: $font-size-base;
    }

    .h3, .h4, .h5 {
      font-size: 1.1rem;
    }

    // .card-header {
    //   flex: 140px 0 0;
    // }

  }

  @include media-breakpoint-up(md) {
    .card-header {
      flex: none;
      width: 350px;
    }
    .card-footer {
      flex: none;
      text-align: right;
    }
  }

  @include media-breakpoint-up(lg) {
    .card-header {
      flex: none;
      width: 420px;
    }
  }

  @include media-breakpoint-up(xl) {
    .card-header {
      flex: none;
      width: 680px;
    }
  }

  @include media-breakpoint-up(xxl) {
    .card-header {
      flex: none;
      width: 750px;
    }
  }
}

.btn-toggle-cards {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}


// Card Section
.card-section {
  //@include media-breakpoint-down(sm) {
    background: none;
    box-shadow: none;
    border: none;

    & > .card-header,
    & > .card-body,
    & > .card-footer {
      padding-left: 0;
      padding-right: 0;
    }

    & > .card-header {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
  //}  
}