.breadcrumb {
  position: relative;
  @include borders($breadcrumb-borders);
  border-bottom: none;
  color: $text-light;
  margin-bottom: 0;
  padding: 0.5rem 0.8rem 1rem;
  @include media-breakpoint-up(sm) {
    padding: 0.8rem 1rem;
  } 
  @include media-breakpoint-up(md) {
    padding: 1rem 1.5rem;
  }  

  a {
    color: $text-light;
  }

  .icon {
    & + .text {
      display: none;
    }
  }
  
}

.breadcrumb-item {
  max-width: 95px;
  background-color: $body-bg;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: .7rem;

  @include media-breakpoint-up(sm) {
    font-size: .9rem;
    max-width: 200px;
  }

  @include media-breakpoint-up(md) {
    font-size: .9rem;
    max-width: 300px;
  }

  @include media-breakpoint-down(xs) {
    font-size: .8rem;   

    & + .breadcrumb-item {
      padding-left: 0.35rem;
    }
  }  

  &.active {
    span {
      font-weight: 500;
      color: $text-light;
    }
  }

  & + .breadcrumb-item {
    &:before {
      padding-right: 0.15rem !important;
      color: $border-color;
    }
  }
}
