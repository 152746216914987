.nav-tabs {
  .nav-link {
    color: $text-light;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: $text-color;
      background: white;
      border-color: $border-color;
      border-bottom-color: white;
      &:focus {
        background: white;
        border-color: $border-color;
        border-bottom-color: white;
      }
    }
  }
}

.tab-content {
  margin-top: -1px;
  background: white;
  border: 1px solid $border-color;
  .tab-pane {
    padding: $spacer;
  }
}

.card-block {
  .tab-content {
    margin-top: 0;
    border: 0;
  }
}


// Page Nav
.page-nav {
  background: $white;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding: 0 1.5rem;

  .nav {
    white-space: nowrap;
    overflow-x: auto;
    display: inline-block;  
    margin-bottom: -6px;
    
    &::-webkit-scrollbar {
      display: none; 
    }
  }

  .nav-item {
    display: inline-block;
    @include media-breakpoint-down(sm) {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 80%;      
    }
  }

  .nav-link {
    padding: 0.8rem 1rem;    
    color: $text-light;
    @include media-breakpoint-up(md) {
      padding: 0.8rem 1.5rem;
    }

    &.active {
      color: $text-color;
    }
  }

  li, a {
    position: relative;
  }

  a {
    color: $text-color;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: transparent;
      transition: .3s;
    }

    &.active,
    &:hover {      
      &:after {
        background: $border-color;
      }
    }

    &.active {
      font-weight: 500; 
    }

    &.disabled {
      color: $text-light;
    }
  }

  // Vertical
  .nav {
    &.flex-column {
      display: block;

      .nav-item {
        display: block;

        a {
          & ~ & {
            margin-top: 1px;
          }

          &.active,
          &:hover {      
            background: $body-bg;
          }

          &:after {
            content: normal;
          }
        }
      }

    }
  }

}