// core overrides



// Button

$input-btn-border-width:            10px;

// Navbar

$navbar-height:                     50px;
$navbar-border: (
  bottom: (
    size:                           1px,
    style:                          solid,
    color:                          transparent
  )
);
$navbar-brand-width:                220px;
$navbar-brand-logo-size:            140px auto;

$navbar-hover-color:                $navbar-color;
$navbar-active-color:               $navbar-color;
$navbar-disabled-color:             $navbar-color;

// Sidebar

$sidebar-width:                     220px;
$sidebar-borders: (
  right: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

// Sidebar Navigation

$sidebar-nav-title-padding-y:       .95rem;
$sidebar-nav-link-borders: (
  bottom: (
    size:                           1px,
    style:                          solid,
    color:                          transparent
  )
);

