.task-summary {
  border-radius: 5px;
  background: $white;
  margin-bottom: 1rem;
  padding: .7rem;
  position: relative;
  box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
  border: 1px solid $border-color;
  @include media-breakpoint-up(sm) {
    padding: 1rem;
  }

  // Content
  &-content {
    position: relative;
    padding-left: 0;
    min-height: 20px;
    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 200px;
    }

    &.has-icon {
      padding-left: 26px;
      @include media-breakpoint-up(sm) {
        padding-left: 30px;
      }
    }
  }

  // Icon
  &-icon {
    position: absolute;
    top: -3px;
    left: 0;
    font-size: 1.2rem;
    min-width: 20px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      top: -7px;
    }
  }

  // Info
  &-info {

  }

  // Title
  &-title {
    margin: 0;
  }

  // Buttons
  &-btns { 
    margin-top: 8px;   
    @include media-breakpoint-up(sm) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      margin-top: 0;
    }
  }

  p {
    margin: 0;
    & ~ p {
      margin-top: .2rem;
    }
  }

  // Timer
  .countdown-timer {
    position: absolute;
    top: -25px;
    right: 0;
  }
}