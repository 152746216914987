.table a {
  color: $link-color;
}

.table-responsive {
  th,
  td {
    white-space: nowrap;
  }

  tbody, thead, tfoot {
    display: table;
    width: 100%;
  }
}

.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: top;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}
