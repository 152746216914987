// Tasks List
// It's used to list all the tasks 

.lessons-builder-tasks-list {
  list-style: none;
  padding-left: 0;

  // Item
  &-item {    
    margin-bottom: 3px;   

    // &[draggable] {
    //   .lessons-builder-task-container {
    //     box-shadow: 0 0 10px rgba(theme-color("primary"), .1);
    //     transform: scale(1.012);        
    //     transition: .2s;        
    //     border: 1px solid theme-color("primary");
    //   }
    // }
  }

  // New
  &-new {
    position: relative;
    .lessons-builder-task-add {
      top: -4px;
    }
  }
}