// Bootstrap overrides

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  false;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  1rem;

// $h1-font-size:                 $font-size-base * 2.5 !default;
// $h2-font-size:                $font-size-base * 2 !default;
// $h3-font-size:                $font-size-base * 1.75 !default;
// $h4-font-size:                $font-size-base * 1.5 !default;
// $h5-font-size:                $font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;


// Breadcrumbs

$breadcrumb-padding-y:            .75rem;
$breadcrumb-padding-x:            2rem;
$breadcrumb-margin-bottom:        1.5rem;


// Dropdowns

$dropdown-padding-y:              0;


// Media Queries

$grid-breakpoints: (
  xs: 0,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);