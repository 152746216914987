.drag-drop-button {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 30px;
  cursor: move;

  &-icon {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: $border-color-dark;
    position: relative;
    margin-right: 3px;
    border-radius: 10px;
    
    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background: $border-color-dark;
      position: absolute;
      left: 0;
      border-radius: 10px;
    }

    &:before {
      top: -7px;
    }

    &:after {
      bottom: -7px;
    }
  }
}