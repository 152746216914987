.lessons-sidebar {

  // Header
  &-header {
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid $border-color;

    &-img {
      width: 110px;      
      overflow: hidden;
      margin-right: 1rem;
    }

    img {
      max-width: 100%;
      border-radius: 5px;
    }

    &-title {
      font-size: 1.2rem;
    }
  }

  // Info
  &-info {
    padding: 1rem;
    label {
      margin-bottom: .2em;
    }
    .form-group {
      margin-bottom: .3em;
    }
  }

  // Content
  &-content {
    
  }

}