.form-control {
  padding: 1.3rem 1.1rem;
  border-radius: 1px;
  border-color: $input-border-color;
  &::placeholder {
    font-style: italic;
    color: $text-light;
  }
  &:disabled {
    background: $input-disabled-bg;
  }
}

.form-spinner-background {
  background-position-x: 99%;
  background-position-y: center;
  background-size: 18px;
  background-repeat: no-repeat;
}

.custom-select,
select.form-control {
 // height: calc(1.5em + 1.3rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 1.1rem;

  &:disabled {
    background: $input-disabled-bg;
  }
}

.help-block {
  font-size: .8em;
  font-style: italic;
  color: $text-muted;
}

.form-control-static {
  font-weight: 500;
}

// Checkboxes and Radios
.form-check,
.form-radio {
  padding-left: 0;
  & ~ & {
    margin-top: .75rem;
  }

  .form-check-label,
  .form-radio-label {
    margin-bottom: 0;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    min-height: 20px;

    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 2px solid $border-color-dark;

      position: absolute;
      top: 1px;
      left: 0;
    }   
  }

    // Is Valid
    &.is-valid {  
        .form-check-label,
        .form-radio-label {
            &:before {
                border-color: theme-color("success") !important;
            }
        }
        
        // Checked
        input[type="radio"],
        input[type="checkbox"] {
            &:checked {
                & + .form-radio-label {
                &:before {
                    border-color: theme-color("success") !important;
                    background: theme-color("success") !important;
                }
                }
        
                & + .form-check-label {
                &:before {
                    border-color: theme-color("success") !important;
                    background: theme-color("success") !important;
                }
                }
            }
        }

        // Disabled
        input[type="radio"],
        input[type="checkbox"] {
            &:disabled {
                & + .form-radio-label,
                & + .form-check-label {
                  &:before {
                    border-color: lighten( theme-color("success"), 10% ) !important;
                  }
                }
                
                &:checked {
                  & + .form-radio-label,
                  & + .form-check-label {
                    &:before {
                        border-color: lighten( theme-color("success"), 15% ) !important;
                        background: lighten( theme-color("success"), 15% ) !important;
                    }
                  }
                }
            }
        }     
    }

    // Is Invalid
    &.is-invalid {
        
        .form-check-label,
        .form-radio-label {
            //color: darken( theme-color("danger"), 10% );
            &:before {
                border-color: theme-color("danger") !important;
            }
        }
        
        // Checked
        input[type="radio"],
        input[type="checkbox"] {
            &:checked {
                & + .form-radio-label {
                &:before {
                    border-color: theme-color("danger") !important;
                    background: theme-color("danger") !important;
                }
                }
        
                & + .form-check-label {
                &:before {
                    border-color: theme-color("danger") !important;
                    background: theme-color("danger") !important;
                }
                }
            }
        }

        // Disabled
        input[type="radio"],
        input[type="checkbox"] {
            &:disabled {
                & + .form-radio-label,
                & + .form-check-label {
                  &:before {
                    border-color: lighten( theme-color("danger"), 15% ) !important;
                  }
                }
                
                &:checked {
                  & + .form-radio-label,
                  & + .form-check-label {
                    &:before {
                        border-color: lighten( theme-color("danger"), 15% ) !important;
                        background: lighten( theme-color("danger"), 15% ) !important;
                    }
                  }
                }
            }
        } 
    }

  .form-radio-label {
    &:before {
      border-radius: 50%;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    // Checked
    &:checked {
      & + .form-radio-label {
        &:before {
          border-color: $input-check-selected;
          background: $input-check-selected;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: white;

          position: absolute;
          top: 6px;
          left: 5px;
        }
      }

      & + .form-check-label {
        &:before {
          border-color: $input-check-selected;
          background: $input-check-selected;
        }

        &:after {
          content: "\f00c";
          color: white;
          display: inline-block;
          font: 11px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          position: absolute;
          top: 6px;
          left: 4px;
        }
      }
    }

    // Disabled
    &:disabled {      
      & + .form-radio-label,
      & + .form-check-label {
        color: theme-color("gray-400");
        &:before {
          border-color: theme-color("gray-300");;
        }
      }
      
      &:checked {
        & + .form-radio-label,
        & + .form-check-label {
          &:before {
            background: theme-color("gray-300");;
          }
        }
      }
    }    
  }

  // Font lg
  .form-check-label,
  .form-radio-label {
    &.font-lg {
      &:before {
        top: 5px;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    &:checked {
      & + .form-radio-label {
        &.font-lg {
          &:after {
            top: 9px;
          }
        }
      }
      & + .form-check-label {
        &.font-lg {
          &:after {
            top: 6px;
          }
        }
      }
    }
  }

}

// Check inline
.form-check-inline {  
  display: block;
  @include media-breakpoint-up(sm) {
    display: inline-block;
    margin-right: 1.5rem !important;
  }
}

// Switch
.form-switch {  
  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + label {
      &:before {
        background: rgba(theme-color("primary"), .4);
      }

      &:after {
        background: darken(theme-color("primary"), 1);
        left: 15px;
        box-shadow: 0 2px 3px rgba(0,0,0,0.3), 0 0 3px rgba(0,0,0,0.1);
      }      
    }

    &:checked {
      &:disabled + label {
        &:before {
          background: theme-color("gray-200");
        }
  
        &:after {
          background: theme-color("gray-400");
        }
      }
    }

    &:disabled + label {
      &:before {
        background: theme-color("gray-200");
      }

      &:after {
        background: theme-color("gray-100");
      }
    }
    
  }

  &-label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    
    &:before,
    &:after {
      content: ''; 
      position: absolute;
      left: 0; 
    }

    &:before {
      background-color: theme-color("gray-400");
      width: 35px;
      height: 15px;
      border-radius: 20px;
      transition: all .2s ease-in-out;
      top: 5px;
    }
    &:after {    
      top: 2px;  
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.4), 0 0 3px rgba(0,0,0,0.1);
      transition: all .2s ease-in-out;
      border-radius: 50%;
      background: #fff;
    }
  }     
}

.form-switch-right {
  .form-switch-input {
    &:checked + label {
      &:after {
        left: inherit;
        right: 0;
      }
    }
  }

  .form-switch-label {
    padding-left: 0;
    padding-right: 45px;
    &:before {
      left: inherit;
      right: 0;
    }

    &:after {
      left: inherit;
      right: 15px;
    }   
  }
}

// Form Search
.form-search {
  position: relative;
  margin-bottom: .3rem;

  .form-search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0 , -50%);
    color: $text-color;
  }

  .form-control {
    padding-left: 30px;
  }
}


.is-required {
    background-color: #fff6f6;
    border: 1px solid #fccac7;
    padding: 1rem;
    border-radius: 5px;

    .mb-5,
    .mb-6,
    .mb-4 {
        margin-bottom: 0 !important;
    }

    .alert {
        border: none;
        background: none;
        padding: 0;
        color: theme-color("danger");
    }
}