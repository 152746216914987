.app-header.navbar {
  position: relative;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  // @include borders($navbar-border);
  border: none !important;
  box-shadow: 0 2px 1px -1px rgba($gray-800,.2), 0 1px 1px 0 rgba($gray-800,.14), 0 1px 3px 0 rgba($gray-800,.12);

  .navbar-brand {
    display: inline-block;
    width: $navbar-brand-width;
    height: $navbar-height;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-color: $navbar-brand-bg;
    // background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
    //@include borders($navbar-brand-border);
    border: none;
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;
    outline: none;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      &.avatar {
        width: auto;
      }

      .profile-name {
        font-size: .9rem;
        .lead {
          font-size: .9rem;
        }
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
        border-radius: 5px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
  }
}

.navbar-brand {
  color: $navbar-active-color;
  background-size: 110px auto !important;

  @include hover-focus {
    color: $navbar-active-color;
  }

  @include media-breakpoint-up(sm) {
    background-size: 130px auto !important;
  }
  
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0,0,0,.075);
}

@include media-breakpoint-up(lg) {
  .brand-minimized {
    .app-header.navbar {
      .navbar-brand {
        width: $navbar-brand-minimized-width;
        background-color: $navbar-brand-minimized-bg;
        background-image: $navbar-brand-minimized-logo;
        background-size: $navbar-brand-minimized-logo-size;
        @include borders($navbar-brand-minimized-border);
      }
    }
  }
}
