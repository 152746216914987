.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $spacer;
  color: $footer-color;
  background: $footer-bg;
  @include borders($footer-borders);
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: center;
  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  .footer-menu {    
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    @include media-breakpoint-up(sm) {
      float: left;
      margin-bottom: 0;
    }
  }

  .footer-menu-item {
    list-style: none;
    display: inline-block;

    & ~ .footer-menu-item {
      margin-left: 10px;
      padding-left: 15px;
      position: relative;
      &:before {
        content: '/';
        position: absolute;
        top: 0;
        left: 0;
        color: $border-color;
      }
    }
  }

  .footer-credits {
    @include media-breakpoint-up(sm) {
      float: right;
    }
  }
}
