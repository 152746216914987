small,
.small {
  font-size: 90%;
}

h1,
.h1 {
  font-size: $font-size-base * 2.1;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 2.3;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 2.5;
  }
}

h2,
.h2 {
  font-size: $font-size-base * 1.8;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.9;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 2;
  }
}
h3,
.h3 {
  font-size: $font-size-base * 1.65;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.75;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.85;
  }
}

h4,
.h4 {
  font-size: $font-size-base * 1.3;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.4;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.5;
  }
}

h5,
.h5 {
  font-size: $font-size-base * 1.15;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.25;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.35;
  }
}

h6,
.h6 {
  font-size: $font-size-base * 1.1;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.15;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.25;
  }
}
