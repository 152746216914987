.new-task-box {
  position: relative;   

  // List
  &-list {
    display: flex;
    position: relative;
    list-style: none;
    padding: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  // Item
  &-item {    
    margin: 6px;
    text-align: center;
    align-self: stretch;

    a {
      background: #fff;
      display: inline-block;
      padding: 1rem;
      border-radius: 5px;
      width: 100px;
      height: 100%;
      text-decoration: none;
      transition: .3s;
      box-shadow: 0 10px 20px rgba($gray-900, .1);

      // &:hover {
      //   box-shadow: 0 10px 20px rgba($gray-900, .1);
      // }
    }
  }

  // Icon
  &-icon {
    font-size: 1.7rem;
    margin-bottom: .2rem;
  }

  // Text
  &-text {
    font-size: 1rem;
    line-height: 130%;
  }
}