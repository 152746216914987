.task-pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;

  // Btn
  &-prev,
  &-next {
    min-width: 100px; 
  } 

  // Title
  &-title {
    display: none;
  }

  // Icon
  &-icon {
    font-weight: normal;
    font-size: 25px;
    color: $text-muted;
  }

  // Label
  &-label {
    position: relative;
    top: -3px;
    display: inline-block;
    margin: 0 10px;
  }

}