#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.icn-gap {
  margin-right: 1%;
}
strong {
  font-weight: 500 !important;
}

.table-header-font-weight{
    font-weight: 400 !important;
}

.edit-question-text{
    font-weight: 400;
    font-size: 90%;
    padding: 0px;
    border: 1px solid rgb(228, 234, 239);
    background-color: white;
}

.margin-left-10{
    margin-left: 9%;
}
.cursor-move{
    cursor: move;
}

.display-block{
    display: block
}

.textbold {
  font-weight: 500;
}
.display-content{
    display: flex;
    justify-content: space-evenly;
}
.pad-10{
    padding: 10px;
}
.width-150 {
  width: 150px;
}
.no-bullet-list {
  list-style: none;
  padding-left: 0;
}

.pad-left{
    padding-left: 1rem;
}
.full-width {
  width: 100%
}

.survey-form-t-margin {
  margin-top: 10px;
}

.imgeditorbtn-width {
  width: 40%;
}
.buttonlink {
  position: absolute;
  height: 30px;
  width: 50px;
  background-color: white;
  border: 1px solid rgb(119, 119, 119);
  box-sizing: border-box;
  opacity: 0.8;
  cursor: pointer;
  color: rgb(51, 51, 51);
  border-radius: 5px;
}
.ql-bubble{
    min-height: 0vh !important;
}

.ql-bubble .ql-editor {
    min-height: 0vh !important;
}

.display-none {
  display: none !important;
}

.loading-spinner {
  margin: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  animation: loading-spinner-spin 1s linear infinite;
  border: 2px solid var(--loading-spinner-outer-color, rgba(0, 0, 0, 0.3));
  border-top: 2px solid var(--loading-spinner-inner-color, #e68900);
}
.whitespace-rmv {
  white-space: normal;
}

.editor-button-container {
  text-align: center;
  margin-top: 30px;
}


.list-lessons-right {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 15px;
  
    .icon {
      top: 2px !important;
      right: 0 !important;
      transform: translate(0) !important;
    }
  }

@keyframes loading-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.quill_responsive_video {
  width: 100%;
  height: 500px;
}

.group-info strong{

    font-weight: bold !important;
}

.ql-editor strong {
  font-weight: bold !important;
}

.quill-height .ql-editor {
  min-height: 45vh;
}

.quill-height .ql-editor img {
  min-width: 200px;
  margin: 10px;
}
img {
  margin: 10px;
}

.ql-custom textarea {
  z-index: 2;
  resize: unset;
}
.ql-container {
  min-height: 45vh;
}
.edit-question-container .ql-container {
    min-height: 10vh;
  }

  .edit-question-container .quill-height .ql-editor{
    min-height: 10vh;
  }

.content-editor .ql-container {
    min-height: 10vh;
  }
  .content-editor .quill-height .ql-editor{
    min-height: 10vh;
  }

.ql-showHtml {
  width: 22vh !important;
}
.ql-showHtml::after {
  content: "<Source>";
}

.padding-info {
  padding: 5px 20px 5px 0px;
}

.imgeditordisplay {
  display: flex;
  flex-direction: column;
}
.btnimgeditorsave {
  display: flex;
  justify-content: flex-end;
}
.divdisplay {
  display: flex;
  padding: 10px;
}

.display-toggle {
  display: flex;
}

.overview {
  width: 60%;
  border-radius: 10px;
  margin: 5px 0;
  min-height: 120px;
  min-width: 120px;
}
.image-position{
    text-align: center;
}
.header-display {
  display: flex;
}
.no-pad {
  padding: 0px;
}
.no-pad-flex {
  margin: 0px;
  padding: 0 20px;
  display: flex;
}
.image-profile {
  width: 200px;
  border-radius: 10px;
}
.toggle-position {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.div-inner {
  position: absolute;
  right: 0;
  bottom: 0;
}
.div-outer {
  display: flex;
  justify-content: flex-end;
}
.span-pad{
       padding-left: 5px;
}

.pad-right {
  padding-right: 10%;
}

.selected-content-file{
    width: 70px;
    height: 60px;
    background-size: cover;
    border: 1px solid #686f7a;
    background-color: gray;
    display: flex;
}
.selected-content-file-img{
width: 100%;
min-width: 40px;
}

.editbtn-width{
    width:90px;
}

.survey-field-icon {
    height: 25px !important;
    width: 25px !important;
}

.preview-btn{
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}
[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #d9dfe3;
  border: none;
  border-radius: 5px;
  color: #2e333e;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  margin-bottom: 1rem;
  outline: none;
  padding: 8px 25px;
}

.flex-container {
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  .hvr-pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
  }
  .hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  

  .flex-container-position {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
  }
  
.align-content {
  display: flex;
  justify-content: flex-end;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

 .content-header-selected{
    border-top: 1px solid rgba(41, 48, 59, 0.25) !important; 
    border-left: 1px solid rgba(41, 48, 59, 0.25) !important;
    border-right: 1px solid rgba(41, 48, 59, 0.25) !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.content-header-left-selected{
    border-top: 1px solid rgba(41, 48, 59, 0.25) !important; 
    border-left: 1px solid rgba(41, 48, 59, 0.25) !important;
    border-right: 1px solid rgba(41, 48, 59, 0.25) !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
// Here you can add other styles
.navbar {
  .form-inline {
    margin-top: 1px;

    .input-group {
      float: none;
    }

    .btn {
      vertical-align: top;
      border: 0;
    }
    .form-control {
      float: none !important;
      color: $navbar-color;
      vertical-align: top;
      background-color: darken(theme-color("gray-800"), 10%);
      border-color: theme-color("danger");
      margin-left: -2px;

      &::placeholder {
        color: $navbar-color;
      }
    }
    .form-control::-webkit-input-placeholder {
      color: $navbar-color;
    }
    .form-control:-moz-placeholder {
      color: $navbar-color;
    }
    .form-control::-moz-placeholder {
      color: $navbar-color;
    }
    .form-control:-ms-input-placeholder {
      color: $navbar-color;
    }

    .input-group-btn {
      height: 40px;
      background-color: theme-color("gray-700");
      padding: 5px;
      .btn {
        height: 30px;
        color: white;
        background-color: theme-color("gray-700");

        &.dropdown-toggle {
          background-color: theme-color("gray-700");
          padding: 0 1rem;
        }
      }
    }
  }

  .avatar {
    img {
      width: 34px;
    }
  }
}

.aside-menu .nav.nav-tabs {
  background-color: theme-color("gray-200");
}

body:not(.sidebar-minimized) {
  .sidebar {
    .nav {
      .nav-item {
        .nav-link {
          i {
            width: 45px;
            height: 45px;
            margin-top: -($sidebar-nav-link-padding-y + 0.25rem) !important;
            margin-right: ($sidebar-nav-link-padding-x / 2);
            margin-bottom: -$sidebar-nav-link-padding-y;
            margin-left: -$sidebar-nav-link-padding-x;
            line-height: 45px;
            color: $sidebar-nav-link-icon-color;
          }
        }
      }
    }
  }
}

.profile-name {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.alert {
  border-radius: 3px;
  padding: 0.3rem 0.8rem;
  margin-bottom: 0.5rem;
  font-style: italic;
}
.nopad {
  padding: 0px;
}
// .navbar-toggler {
//     display: none !important;
// }

.ql-editor .quill-header-1 {
  font-weight: 700 !important;
}
.ql-editor .quill-header-2 {
  font-weight: 500 !important;
}

.mb-6 {
  margin-bottom: 1rem * 4 !important;
}

.mb-5 {
  @include media-breakpoint-up(md) {
    margin-bottom: 1rem * 4 !important;
  }
}

// Reboot
.hr,
hr {
  border-color: $border-color;
}

//===================================================================
// @media
//===================================================================

@media only screen and (max-width: 569px) {
  // .htmlPreview {

  // }
  .quill_responsive_video {
    height: 150px;
  }

  .display-toggle {
    display: block;
  }
  .nomargin {
    margin: 0px !important;
  }
  .overview {
    width: 35%;
  }
  .header-display{
      display: block
  }
  .image-position{
      text-align: left;
  }
  .list-lessons-right {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .toggle-position {
    display: block;
    justify-content: flex-end;
    width: 100%;
  }
  .display-toggle {
    display: block;
  }
  .div-inner {
    position: relative;
  }
  #overview img {
    width: 100%;
  }
  .list-lessons-right {
    position: relative;
  }
}
