.question-grid {
  // Answers
  &-anwsers {
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
  }

  // Container
  &-container {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;    
  }

  // Table
  &-table {
    border-collapse: collapse;
    display: table;
    width: 100%;
  }

  // Table header
  &-header {
    line-height: 115%;
    // font-size: .8rem;
  }

  // Table Row
  &-row {
    display: table-row;
  }

  // Table Row Group
  .form-group {
    display: table-row-group;
    background: $white;
    &:nth-child(even) {
      background: theme-color("light");
      transition: 0.3s;
    }
    &:first-child {
      background: $white;
    }    
  }

  .question-grid-title {
    background: white;
  }

  // Table Cel
  &-cel,
  .form-radio,
  .form-check,
  .form-group-title {
    display: table-cell;
    min-width: 48px;
    max-width: 144px;
    padding: 8px 8px 8px 0;
    text-align: left;
    height: 55px;
    width: 48px;
    vertical-align: middle;
    padding: 8px 8px 8px 0;
    text-align: center;
    font-size: 0.8rem;
    @include media-breakpoint-up(xs) {
      font-size: 0.85rem;
    }

    &.question-grid-title {
      min-width: 48px;
      max-width: 288px;
      padding: 8px 8px 8px 0;
      text-align: left;
    }
  }

  label {
    margin-top: 4px;
  }

  .form-group-title {
    text-align: left;
  }
  
  .form-group-title h3 {
    font-weight: normal;
    line-height: 140%;    
    margin-bottom: 0 !important;
    padding-right: 5px;
    padding-left: 5px;  
    font-size: 0.8rem;
    @include media-breakpoint-up(xs) {
      font-size: 0.85rem;
    }  
  } 
  
  // Duplicated Effect
  .question-duplicated {
    background-color: transparent;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;    

    .question-grid-cel:not(.question-grid-title),
    .form-group .form-radio,
    .form-group .form-check {
      visibility: hidden;
    }

    .form-group {
      display: table-row;
    }    
  }

  // Shawdow
  .question-grid-shadow {
    background: linear-gradient(to left,white,transparent);
    height: -webkit-calc(100% - 12px);
    height: calc(100% - 12px);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 13px;
    display: none;
    opacity: .6;
  }

  .hasRightScroll {
    .question-grid-shadow {
      display: block;
    }
  }

  .isScrolling {
    .question-duplicated {
      .form-group-title {
        box-shadow: 1px 0 47px -1px #e0e0e0;
      }

      .question-grid-title {
        box-shadow: 5px 0 5px -1px white;
      }
    }
  } 

}
