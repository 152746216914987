.badge {
  font-weight: normal;
  border-radius: 3px;
  padding-bottom: .5em;
}

.badge-primary {
    color: white;
}

.badge-light {
  color: $text-color;
}

.badge-pill {
  border-radius: 50px;
}

.badge-filter {
  background: $white;
  border: 1px solid $border-color-dark;
  margin: 2px;
  font-size: 0.9rem;
  padding-bottom: 5px;
  padding-right: 12px;
  outline: none;
  &-btn {
    border: none;
    background: none;
    color: $text-light;
    font-weight: normal;
    line-height: 0;
    margin-left: -4px;
    margin-top: -2px;
    outline: none;    
    padding: 0 .5rem;
    
    &:hover {
      color: $text-color;
    }
  }
}


// Badge Outline

.badge-outline-primary {
  border: 1px solid theme-color("primary");
  color: theme-color("primary");
}

.badge-outline-secondary {
  border: 1px solid theme-color("secondary");
  color: theme-color("secondary");
}

.badge-outline-success {
  border: 1px solid theme-color("success");
  color: theme-color("success");
}

.badge-outline-info {
  border: 1px solid theme-color("info");
  color: theme-color("info");
}

.badge-outline-warning {
  border: 1px solid theme-color("warning");
  color: theme-color("warning");
}

.badge-outline-danger {
  border: 1px solid theme-color("danger");
  color: theme-color("danger");
}

.badge-outline-light {
  border: 1px solid theme-color("light");
  color: theme-color("light");
}

.badge-outline-dark {
  border: 1px solid theme-color("dark");
  color: theme-color("dark");
}