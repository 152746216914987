.table-group {
  @include clearfix;  

  // Controls
  &-header {
    @include clearfix;
    position: relative;
    margin-bottom: 10px;
    min-height: 40px;

    &.hasInfo {
      padding-right: 100px;
      .table-group-info {
        position: absolute;
        right: 0;
        top: 6px;
      }
    }

    &.hasCheck {
      padding-left: 40px;
      .form-check {
        position: absolute;
        top: 6px;
        left: 10px;
      }
    }
  }

  // Actions
  &-actions {
    float: left;    
  }

  // Info
  &-info {
    float: right;
  }
}

// List Table
.list-table {
  background-color: transparent;
  border-collapse: collapse;
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
  background: $white;
  border: 1px solid $border-color;
  border-radius: 5px;
  box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);

  // Row
  &-row{
    border-bottom: 1px solid $border-color;
    width: 100%;
    position: relative;
  }

  // Col
  &-col{
    padding: 3px 20px;
    text-align: left;
    vertical-align: middle;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  // Table Has Checkbox
  &.hasCheck {
    .list-table-col:first-child {
      width: 100%;
      padding: 15px 20px;
    }
  }

  // Table Has Dropdown Button
  &.hasDropDown {
    .list-table-col:last-child {
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
    }
  }

  // Header 
  &-header {
    display: none;
    color: $text-dark;
    background: $body-bg;
    .list-table-col {
      padding-bottom: 20px !important;
      padding-top: 20px !important;
    }
  }

  // Progress
  .progress-component {
    margin-bottom: 8px;
  }

  // Btn
  .btn {
    white-space: nowrap;
  }

  // Status
  &-status {
    font-style: italic;
    font-weight: 500;
  }

  // Info
  &-info {
    display: none;
    &.show {
      display: table-row;
    }
  }

  // Group Btns
  .group-btns {
    display: flex;
    align-items: center;
  }
}

// Btns
.list-table-col-btns { 
  padding-bottom: 20px;

  .btn {
    margin-left: 0 !important;
  }

  div {
    display: inline;
  }
}


// Mixin Style for breakpoints
@mixin breakpoints-style() {
  &.hasCheck {
    .list-table-col:first-child {
      width: 35px;
    }
  }

  .list-table-row{
    display:table-row;
    border-collapse: collapse;
    width: 100%;

    &:hover {
      .list-table-col-btns {
        & > * {
          opacity: 1;
        }
      }
    }
  }

  .list-table-col{
    display: table-cell;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 15px 15px;
    border-collapse: collapse;    

    .label-hidden {
      display: none;
    }
  }
  
  .list-table-header {
    display: table-row;
  }

  // Has Dropdown
  &.hasDropDown {
    .list-table-col:last-child {
      width: 65px;
      position: relative;
      .btn {
        margin-left: 3px;
      }
    }
  }

  // Btns
  .list-table-col-btns {
    width: 265px;
    text-align: right;
    & > * {
      opacity: 0;
    }
  }
}


// List Table from sm
.list-table {
  &.fromSm {
    @include media-breakpoint-up(sm) {
      @include breakpoints-style(); 
    }
  }
}

// List Table from md
.list-table {
  &.fromMd {
    @include media-breakpoint-up(md) {
      @include breakpoints-style(); 
    }
  }
}

// List Table from lg
.list-table {
  &.fromLg {
    @include media-breakpoint-up(lg) {
      @include breakpoints-style(); 
    }
  }
}


// Table Info
.list-table-info {
  @include clearfix;
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 100;
  background: $white;  
  clear: both;    
  display: none;
  box-shadow: 0 3px 2px 0 rgba(69, 90, 100, 0.05);
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color;
  &.show {
    display: flex;
  }

  &-content {
    padding: 40px 20px;
    width: 100%;
  }

  .row {
    width: 100%;
  }

  .lastActivities {
    display: none;
    &.show {
      display: block;
    }
  }
}
