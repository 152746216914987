.header-page {
    padding: .7rem 0;
    position: relative;
    text-align: left;
    @include media-breakpoint-up(sm) {
      padding: 1.1rem 0;
    }
    @include media-breakpoint-up(md) {
      padding: 1.5rem 0;
    }

    &.hasBtnBack {
        @include media-breakpoint-down(md) {
            padding-left: 30px;
        }  
        
        @include media-breakpoint-up(md) {            
            text-align: center;
            padding-left: 150px;
        }

        &.hasLessons {
            @include media-breakpoint-up(md) {            
                //text-align: left;
                padding-right: 190px;
                padding-left: 190px;
            }
        }
    }

    &.hasInfo {
        @include media-breakpoint-up(md) {
            padding-right: 150px;
        }
    }

    .btn-back {
        position: absolute;
        left: 0;
        top: 46%;
        transform: translate(0, -50%);
    }

    .header-page-title {
      line-height: 135%;
    }

    .header-page-info {  
        font-size: 105%;
        font-weight: 500;      
        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
            top: 1.7rem;
        }

        i {
            margin-right: 3px;
            display: inline-block;
            color: theme-color("primary");
            font-weight: bold;
        }
    }
}