.question-linearScale {

    &-anwsers {
        display: flex;
        width: 100%;
    }   

    &-column,
    .form-radio {
        display: flex;
        flex-grow: 1;
        box-align: stretch;
        align-items: stretch;
        flex-direction: column;
        text-align: center;
        max-width: 25%;
    }

    &-cel {
        box-align: center;
        align-items: flex-end;
        display: flex;
        box-pack: center;
        justify-content: center;
        min-height: 2em;
        line-height: 140%;
        @include media-breakpoint-down(sm) {
            font-size: .75rem;
            line-height: 140%;
        }
    }

    .form-radio {
        margin-top: 0 !important;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 32px;
          // background: darken($gray-100, 5%);
          z-index: 0;
          position: absolute;
          bottom: -5px;
        }
    }

    .form-radio-label {
        box-align: center;
        align-items: flex-start;
        display: flex;
        box-pack: center;
        justify-content: center;
        min-height: 4em;

        padding-left: 0;
        padding-bottom: 25px;

        &:before {
            top: inherit;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &:after {
            top: inherit !important;
            bottom: 5px;
            left: 50% !important;
            transform: translate(-50%, 0) !important;
        }
    }

}