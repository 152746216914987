button {
  cursor: pointer;
}

.btn-transparent {
  color: white;
  background-color: transparent;
  border-color: transparent;
}

.btn {
  [class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
  }
  border-radius: $border-radius;
  line-height: 128%;
}

.btn-groupadmin {
    color: #2e7d31;
    transition: .3s;
    background-color: #e6ece6;
    border-color: #b0b8c2;
    &:hover {
      color: $text-dark;
      text-decoration: none;
    }
}

.btn-pending {
  color: #f44336;   
  transition: .3s;
  background-color: #f3e7e6;
  border-color: #f44336;
  &:hover {
    color: $text-dark;
    text-decoration: none;
  }
}
.padding {
    padding-left: 2%;
}

.btn-back {
  color: $text-light;
  transition: .3s;
  &:hover {
    color: $text-dark;
    text-decoration: none;
  }

  
  .label {
    @include media-breakpoint-down(sm) {
      display: none;
    } 
  }

  .icon {
    display: inline-block;
    margin-right: .5rem;
  }
}

.btn-primary,
.btn-warning{
  color: white;
  &:hover {
    color: white;
  }
}

.btn-outline-light {
  color: $body-color;
  &:hover {
    color: $text-dark;
  }
}

.btn-icon {
  position: relative;
  padding-right: 25px;

  .icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 12px;
  }
}

.btn-circle {
  margin-right: 3px;
  margin-bottom: 3px;
  width: 30px;
  height: 38px;
  border-radius: 4px;
  padding-right: 5px;
  padding-left: 5px;
  position: relative;
}

.btn-inline-block {
  margin-right: 3px;
  margin-bottom: 3px;
  width: 30px;
  height: 38px;
  border-radius: 4px;
  padding-right: 5px;
  padding-left: 5px;
  position: relative;
  text-align: center;
}

.btn-display-flex {
  display: flex;
}

.btn-align-right {
  text-align: right;
  margin-bottom: 20px;
}

.btn-block {
  width: 100%;
  text-align: left;
  white-space: inherit;
}

.btn-primary {
    &:disabled,
    &.disabled {
        color: $white;
    }
}

.btn-loader {
    .sk-rotating-plane {
        background: white;
        border-radius: 2px;
        width: 15px;
        height: 20px;
        float: left;
        margin: 0 10px 0 0;
    }   
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.display-none{
    display: none
}
.btn-facebook,
.btn-twitter,
.btn-linkedin,
.btn-flickr,
.btn-tumblr,
.btn-xing,
.btn-github,
.btn-html5,
.btn-openid,
.btn-stack-overflow,
.btn-youtube,
.btn-css3,
.btn-dribbble,
.btn-google-plus,
.btn-instagram,
.btn-pinterest,
.btn-vk,
.btn-yahoo,
.btn-behance,
.btn-dropbox,
.btn-reddit,
.btn-spotify,
.btn-vine,
.btn-foursquare,
.btn-vimeo {
  position: relative;
  overflow: hidden;
  color: white !important;
  text-align: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  &:hover {
    color: white;
  }

  &.icon {

    span {
      display: none;
    }
  }

  &.text {
    &::before {
      display: none;
    }

    span {
      margin-left: 0 !important;
    }
  }
  @include button-social-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $line-height-base, $btn-border-radius);

  &.btn-lg {
    @include button-social-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
  }

  &.btn-sm {
    @include button-social-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
  }

}

.btn-facebook {
  $color: $facebook;

  background: $color;
  &::before {
    content: "\f09a";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-twitter {
  $color: $twitter;

  background: $color;
  &::before {
    content: "\f099";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-linkedin {
  $color: $linkedin;

  background: $color;
  &::before {
    content: "\f0e1";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-flickr {
  $color: $flickr;

  background: $color;
  &::before {
    content: "\f16e";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-tumblr {
  $color: $tumblr;

  background: $color;
  &::before {
    content: "\f173";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-xing {
  $color: $xing;

  background: $color;
  &::before {
    content: "\f168";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-github {
  $color: $github;

  background: $color;
  &::before {
    content: "\f09b";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-html5 {
  $color: $html5;

  background: $color;
  &::before {
    content: "\f13b";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-openid {
  $color: $openid;

  background: $color;
  &::before {
    content: "\f19b";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-stack-overflow {
  $color: $stack-overflow;

  background: $color;
  &::before {
    content: "\f16c";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-css3 {
  $color: $css3;

  background: $color;
  &::before {
    content: "\f13c";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-youtube {
  $color: $youtube;

  background: $color;
  &::before {
    content: "\f167";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-dribbble {
  $color: $dribbble;

  background: $color;
  &::before {
    content: "\f17d";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-google-plus {
  $color: $google-plus;

  background: $color;
  &::before {
    content: "\f0d5";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-instagram {
  $color: $instagram;

  background: $color;
  &::before {
    content: "\f16d";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-pinterest {
  $color: $pinterest;

  background: $color;
  &::before {
    content: "\f0d2";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-vk {
  $color: $vk;

  background: $color;
  &::before {
    content: "\f189";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-yahoo {
  $color: $yahoo;

  background: $color;
  &::before {
    content: "\f19e";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-behance {
  $color: $behance;

  background: $color;
  &::before {
    content: "\f1b4";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-dropbox {
  $color: $dropbox;

  background: $color;
  &::before {
    content: "\f16b";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-reddit {
  $color: $reddit;

  background: $color;
  &::before {
    content: "\f1a1";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-spotify {
  $color: $spotify;

  background: $color;
  &::before {
    content: "\f1bc";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-vine {
  $color: $vine;

  background: $color;
  &::before {
    content: "\f1ca";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-foursquare {
  $color: $foursquare;

  background: $color;
  &::before {
    content: "\f180";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

.btn-vimeo {
  $color: $vimeo;

  background: $color;
  &::before {
    content: "\f194";
    background: darken($color, 5%);

  }

  &:hover {
    background: darken($color, 5%);

    &::before {
      background: darken($color, 10%);
    }
  }
}

// Button Dashes
.btn-dashes {
  border: 2px dashed $border-color;
  color: $border-color-dark;
  display: block;
  width: 100%;
  padding: 1.5em;

  &:hover {
    border: 2px dashed $border-color-dark;
    color: theme-color("primary");
  }
}
