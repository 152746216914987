.video-responsive {
  @include media-breakpoint-down(md) {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  iframe {
    @include media-breakpoint-down(md) {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    display: block;
    margin: 0 auto;
  }

  &.expanded {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  
}
