//
// Color system
//

$white:                             #ffffff !default;
$gray-100:                          #f4f9fb;
$gray-200:                          #e4eaef;
$gray-300:                          #d9dfe3;
$gray-400:                          #b0b8c2;
$gray-500:                          #747d8d;
$gray-600:                          #58606e;
$gray-700:                          #475060;
$gray-800:                          #3a4250;
$gray-900:                          #2e333e;
$black:                             #000000 !default;

$blue:                              #00a1f0;
$indigo:                            #6610f2 !default;
$purple:                            #6f42c1 !default;
$pink:                              #e83e8c !default;
$red:                               #f44336;
$orange:                            #ff9800;
$yellow:                            #ffc167 !default;
$green:                             #4caf50;
$teal:                              #20c997 !default;
$cyan:                              #67c2ef; 

// Brand Colors
$brand-primary:                     #ff9800;
$brand-secondary:                   #cd7209;

// Bg Gradient / Home Page
$bg-gradient-initial:              #343a5d;
$bg-gradient-middle:               #ffac5f;
$bg-gradient-end:                  #ffe05f;

$colors: (
  blue:                             $blue,
  indigo:                           $indigo,
  purple:                           $purple,
  pink:                             $pink,
  red:                              $red,
  orange:                           $orange,
  yellow:                           $yellow,
  green:                            $green,
  teal:                             $teal,
  cyan:                             $cyan,
  white:                            $white,
  gray:                             $gray-600,
  gray-dark:                        $gray-800
);

$theme-colors: (
  primary:                          $brand-primary,
  secondary:                        $brand-secondary,
  success:                          $green,
  info:                             $blue,
  warning:                          $yellow,
  danger:                           $red,
  light:                            $gray-300,
  dark:                             $gray-900,
  text:                             $gray-700,
  muted:                            $gray-500,

  blue:                             $blue,
  indigo:                           $indigo,
  purple:                           $purple,
  pink:                             $pink,
  red:                              $red,
  orange:                           $orange,
  yellow:                           $yellow,
  green:                            $green,
  teal:                             $teal,
  cyan:                             $cyan,

  gray-100:                         $gray-100,
  gray-200:                         $gray-200,
  gray-300:                         $gray-300,
  gray-400:                         $gray-400,
  gray-500:                         $gray-500,
  gray-600:                         $gray-600,
  gray-700:                         $gray-700,
  gray-800:                         $gray-800,
  gray-900:                         $gray-900,
  black:                            $black,  
);

// Body
$body-bg:                           $gray-100;
$body-color:                        $gray-700;

// Text / Title
$text-color:                        $body-color;
$text-light:                        $gray-500;
$text-dark:                         $gray-900;
$text-muted:                        $text-light;
$title-color:                       $text-color;

// Link
$link-color:                        darken( $orange, 5% ) !default;

// Border
$border-color:                      $gray-200;
$border-color-dark:                 $gray-400;

// List
$list-border:                       $border-color;
$list-bg:                           $body-bg;
$list-color:                        $text-color;
$list-link-color:                   $link-color;
$list-disabled-color:               $gray-400;

// Navbar
$navbar-bg:                         $text-dark;
$navbar-color:                      $text-color;
$navbar-hover-color:                $text-dark;
$navbar-active-color:               $text-dark;
$navbar-disabled-color:             $gray-300;
$navbar-brand-bg:                   $gray-800;
$navbar-brand-logo:                 '';
$navbar-brand-minimized-logo:       '';

// Sidebar
$sidebar-bg:                        $gray-800;
$sidebar-color:                     $white;
$sidebar-nav-color:                 $gray-800;
$sidebar-nav-link-color:            $white;

$sidebar-nav-title-color:           $gray-200;

$sidebar-nav-link-color:            $white;
$sidebar-nav-link-icon-color:       $gray-400;
$sidebar-nav-link-hover-color:      theme-color("primary");
$sidebar-nav-link-hover-bg:         darken($sidebar-bg, 5%);
$sidebar-nav-link-hover-icon-color: theme-color("primary");


$sidebar-nav-link-active-color:     $gray-300;
$sidebar-nav-link-active-bg:        transparent;
$sidebar-nav-link-active-icon-color:$white;

$sidebar-nav-dropdown-color:        $white;
$sidebar-nav-dropdown-bg:           darken($sidebar-bg, 3%);
$sidebar-nav-dropdown-indicator-color:$gray-600 !default;

$sidebar-minimizer-bg:              transparent;
$sidebar-minimizer-indicator-color:   $gray-600 !default;

$sidebar-minimizer-hover-bg:        darken($sidebar-bg, 5%);

// Top Navigation

$top-nav-bg:                        $white;
$top-nav-color:                     $body-color;
$top-nav-hover-color:               $white;
$top-nav-hover-bg:                  theme-color("primary") !default;
$top-nav-active-color:              $white;
$top-nav-active-bg:                 theme-color("primary") !default;

// Breadcrumb
$breadcrumb-bg:                     $gray-100;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-800;

// Cards
$card-border-color:                 $border-color;
$card-cap-bg:                       $gray-100;
$card-icon-color:                   $body-color !default;

// Dropdowns
$dropdown-border-color:             $border-color;
$dropdown-divider-bg:               $gray-100;

// Buttons
$btn-secondary-border:              $border-color;

// Progress bars
$progress-bg:                       $gray-100;

// Tables
$table-bg-accent:                   $gray-100;
$table-bg-hover:                    $gray-100;
$table-hover-bg:                    $gray-100;

// Forms
$input-group-addon-bg:              $gray-100;
$input-border-color:                $border-color;
$input-group-addon-border-color:    $border-color;
$input-disabled-bg:                 darken($gray-100, 4%);
$input-disabled-border:             darken($gray-300, 11%);
$input-check-selected:              $brand-primary;

// Footer
$footer-bg:                         $body-bg;
$footer-color:                      $text-color;



// Social Colors
$facebook:                            #3b5998 !default;
$twitter:                             #00aced !default;
$linkedin:                            #4875b4 !default;
$google-plus:                         #d34836 !default;
$flickr:                              #ff0084 !default;
$tumblr:                              #32506d !default;
$xing:                                #026466 !default;
$github:                              #4183c4 !default;
$html5:                               #e34f26 !default;
$openid:                              #f78c40 !default;
$stack-overflow:                      #fe7a15 !default;
$youtube:                             #b00 !default;
$css3:                                #0170ba !default;
$dribbble:                            #ea4c89 !default;
$google-plus:                         #bb4b39 !default;
$instagram:                           #517fa4 !default;
$pinterest:                           #cb2027 !default;
$vk:                                  #45668e !default;
$yahoo:                               #400191 !default;
$behance:                             #1769ff !default;
$dropbox:                             #007ee5 !default;
$reddit:                              #ff4500 !default;
$spotify:                             #7ab800 !default;
$vine:                                #00bf8f !default;
$foursquare:                          #1073af !default;
$vimeo:                               #aad450 !default;