// Single Task in the list
// It's used to wrap a single task that contains all information about its

.lessons-builder-task {
  position: relative;
  margin-top: 24px;  

  // Add
  &-add {
    position: absolute;
    top: -24px; 
    left: -24px;
    opacity: 0;
    transition: .2s;
    z-index: 2;
  }

  // Container
  &-container {
    border-radius: 4px;
    background: #fff;
    padding: 0.3rem 0.8rem;
    border: 1px solid $gray-300;
    cursor: move;    
    box-shadow: 0 2px 1px -1px rgba(58, 66, 80, 0.2), 
                0 1px 1px 0 rgba(58, 66, 80, 0.14), 
                0 1px 3px 0 rgba(58, 66, 80, 0.12);
  }

  // Header
  &-header {
    position: relative;
    display: flex;
    color: $text-light;
    align-items: center;
    font-size: .92rem;

    // Info
    &-info {
      display: flex;
      align-items: center;
    }

    // Type
    &-type {
      width: 100px;
      margin-right: .7rem;
    }
    
    // Title
    &-title {
      margin: 5px 0;
      color: $body-color;
      margin-bottom: 0 !important;
      font-size: .92rem !important;
    }

    // Actions
    &-actions {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    // Options
    &-options {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 20px;
      }
    }

    // Btns
    &-btns {
      display: flex;
      .btn {
        margin-top: -3px;
        height: 40px;
        opacity: .7;
        transition: .3s;
        outline: none;
        &:hover {
          opacity: 1;
        }
      }
    }    

  }

  // Toggle Btn
  &-toogle-btn {
    font-size: 20px;
    outline: none;
    position: relative;
    top: -3px;
    color: $text-muted;
  }

  // New Task
  &-options,
  &-new {
    position: relative;
    background: rgba($gray-300, 1);
    border-radius: 6px;
    border: 1px solid $border-color;
    margin-bottom: 23px;
    padding: 1.5rem; 
  }

  &-new {
    .editor-container {
      padding: 1.25rem;
    }
  }

  // New Content
  &-content-add {
    text-align: right;
    padding: .5rem 0;

    .btn-outline-primary {
      &:hover {
        background: #fff;
        color: $brand-secondary;
      }
    }
  }
  
}