.list-tasks {
    
}

.list-tasks-item {
    position: relative;

    // Disabled Task
    &.disabled {
        color: $list-disabled-color;

        i {
          color: $list-disabled-color !important;
        }
    }

    &.active {
      .list-tasks-icon {
        color: $list-color;
      }

      .list-tasks-status {
        font-weight: normal;
      }
    }
}

.list-tasks-title { 
    padding-right: 40px;
    padding-left: 23px;
    position: relative;
    @include media-breakpoint-up(sm) {
        padding-right: 200px;
    }
    @include media-breakpoint-up(md) {
      font-size: $font-size-base * 1.05;      
    }
    @include media-breakpoint-up(lg) {
      font-size: $font-size-base * 1.1;
      padding-right: 300px;
    }
}

.list-tasks-icon {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 0.9rem;
  color: lighten($list-color, 35%);

  @include media-breakpoint-up(md) {
    font-size: 1rem;
    top: 4px;
  }
}

.list-tasks-info {
  font-weight: bold; 

  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 250px;
    top: 50%;
    transform: translate(0, -50%);
    text-align: right;
    margin-top: 0;
  }  

  .badge {
    background: lighten(theme-color("warning"), 20%);
  }
}

.list-tasks-status {     
    @include media-breakpoint-up(sm) {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        text-align: right;
        margin-top: 0;       
    } 

    @include media-breakpoint-up(lg) {
      width: 220px;
    }  

    &.has-action {  
        @include media-breakpoint-up(sm) {
            right: 55px;
        } 
    }

    // Icon
    &-icon {
      @include media-breakpoint-down(xs) {
        position: absolute;
        top: .6rem;
        right: 1rem;
      }       
    }
}

.list-tasks-actions {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
    
    // Checkboxes state
    .form-check {
        top: 1px;
        // Success
        &.success {
            input[type="checkbox"] {
                &:checked {
                    & + .form-check-label {
                        &:before {
                            border-color: theme-color("success");
                            background: theme-color("success");
                        }
                    }
                }
            }
        }        
    }
}

.list-tasks-status-icon {
  display: inline-block;
  margin-left: 5px;
}