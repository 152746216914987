.colors-wrap {
  display: flex;
  
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.colors-item {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    width: 100px;
    height: 100px;
  }
}