.list-results {
    max-height: 188px;
    overflow-y: auto;

    background: white;
    border: 1px solid $list-border;
    border-radius: 7px;

    box-shadow: 0 5px 20px 0 rgba(69, 90, 100, 0.2);
    
    &-item {
        position: relative;
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
        border-right: none;
        border-left: none;

        &:first-child {
            border-top: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &-info {
        padding-right: 50px
    }

    &-title {
        margin: 0 0 -3px;   
        font-size: .93rem;    
    }

    &-description {
        font-size: .8rem;
        font-style: italic;
        color: $list-color;
    }

    &-action {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translate(0, -50%);
        color: $list-color;
        font-style: italic;
    }
}