.lessons-collapse {

  // Group
  &-group { 
    
    &.active {
      background: #fff;
    }

    & ~ .lessons-collapse-group {
      border-top: 1px solid $border-color;
    }
  }

  // Btn
  &-btn {
    padding: 1rem;
    cursor: pointer;
    position: relative;
    padding-right: 40px;
  }
  
  &-toogle {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  // Info
  &-info {
    margin: 0 0 .2em 0;
    color: $text-muted;    
  }

  // Title
  &-title {
    font-size: 1rem;
    margin: 0;
    color: $text-dark;
  }  

  // Content
  &-content {
    margin-top: -0.3rem;
    padding-bottom: 1rem;
  }

  // List
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-list-item {
    padding: 0.4rem 1rem 0.4rem 58px;
    position: relative;
    font-size: .93em;
    cursor: pointer;

    &:hover {
      background: lighten($gray-500, 46%);
      color: $text-dark;
    }

    &.active,
    &.active:hover {
      background: lighten($blue, 48%);
      color: theme-color("info");
    }    

    .icon-status {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: #fff;
      border: 1px solid $gray-500;
      border-radius: 50%;
      position: absolute;
      top: 0.6rem;
      left: 1rem;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.6em;
        color: #fff;
      }    

      &.required {
      }
      
      &.done {
        background: $gray-500;
      }

      &.optional {
        border-style: dashed;
      }
    }

    .icon-type {
      display: inline-block;
      position: absolute;
      top: 0.75rem;
      left: calc(1rem + 23px);
      font-size: 0.87em;
    }

  }

}