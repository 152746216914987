.progress {
  margin-left: 15px;
  background: theme-color("gray-200");
}

.progress-component {
  position: relative;
  padding-left: 37px;
}

.progress-number {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  color: theme-color("primary");
  font-weight: 500;
}

.progress-bar {
  transition: none;
}
