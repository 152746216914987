.support-message {
  background: theme-color("gray-500");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;

  .container {
    max-width: 500px;
  }

  &__content {
    color: white;
  }

  &__description {
    font-size: 1rem;
  }

  &__links {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      font-size: 1rem;
    }
  }

  a {
    color: white;
  }
}