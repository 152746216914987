.course-header {
    padding: 0 0 1rem 0;
    @include media-breakpoint-up(md) {
      padding: 0.3rem 0 1.5rem 0;
    } 

    .header-wrapper {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(md) { 
        flex-direction: row;
      }

      img {
        max-width: 200px;        
        margin-bottom: 1rem !important;
        width: 135px;
        height: 135px;
        @include media-breakpoint-up(md) { 
          margin-bottom: 0 !important;
          margin: 0;
          margin-right: 4%;
        }
        
      }
    }    
}

.page-nav {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}