@each $color, $value in $theme-colors {
  .modal-#{$color} {

    .modal-content {
      border-color: $value;
    }

    .modal-header {
      color: white;
      background-color: $value;
    }
  }
}
// .modal-overlay {
// }
.modal-content {
  display: flex;
}

.dropZone-area {
  height: 100%;
  width: 100%
}

*[class*="_overlay_"] {
  z-index: 1100;
}

*[class*="_closeButton_"] {
  opacity: .5;
  transition: .3s;
  &:hover {
    opacity: 1;
  }
}