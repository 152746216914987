.list-page {

  // Header
  &-header {
    @include clearfix;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;
  }

  // Actions
  &-actions {
    @include clearfix;
    position: relative;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  // Controls 
  &-controls {
    margin: 10px 0 0 0;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin: 0 0 0 20px;
    }

    .btn-group { 
      margin: 0 2px;
      &:last-child {
        margin-left: 10px; 
      }
    }

    .btn-lg {
      @include media-breakpoint-down(xs) {
        padding: 0.5rem .3rem;
      }      
    }

    &.hasAddBtn {
      .btn-group:last-child {
        float: right;
      }      
    }

    .btn-label {
      display: none;
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  } 

  // Search
  &-search {
    width: 100%;  
    @include media-breakpoint-up(md) {
      width: 260px; 
    }
    @include media-breakpoint-up(lg) {
      width: 300px; 
    }

    .form-control {
      padding-top: .8rem;
      padding-bottom: .8rem;
    } 
  }  

  // Filters
  &-filters {
    @include clearfix;
    position: relative;

    .badge-filter {
      margin: 15px 3px 0 0;
    }
  }

  // Footer
  &-footer {
    margin: 20px 0 10px;
    text-align: center;
  }

}