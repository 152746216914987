.list-users {

  // Trainee Name
  .list-group-info {
    display: none;
    font-weight: bold;
    &:before {
      content: "\E005";
      display: inline-block;
      font-family: 'simple-line-icons';
      speak: none;
      font-style: normal;
      font-weight: 500;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 5px;
      color: theme-color("primary");
      font-size: .8rem;
    }
  }

  // Below sm...
  @include media-breakpoint-down(sm) {
    .list-group-item {
      &:first-child {
        border-radius: 7px;
      }
    }

    .list-group {
      border-radius: 7px;
    }

    .list-group-item-action {
      display: none;
      color: $list-color;
      .icon {
        display: none;
      }
    }

    .list-group-info {
      display: block;
      color: $list-color;
    }

    .list-group-item-title {
      padding: 0;
      margin-bottom: 0.3rem;
      text-align: left !important;
      font-weight: normal;

      &:after {
        content: "\F107";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 5px;
        font-weight: normal;
        position: absolute;
        top: 50% !important;
        right: 10px !important;
        transform: translate(0, -50%) !important;
      }
    }

    // Active
    .list-group.active {
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);

      .list-group-item {
        &:first-child {
          border-radius: 7px 7px 0 0;
        }
      }

      .list-group-item-action {
        display: block;
      }

      .list-group-item-title {  
        &:after {
          content: "\F106";
        }
      }
    }
  }

  &.lg {
    .list-group-item {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
