.list-lessons {
  border-radius: 6px;
  @include media-breakpoint-down(sm) {
    box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
  }

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
    transition: .3s;
  }

  & > .list-group-item-action {
    &:hover {
      background: $white;
    }
  }
}

.list-lessons-item {
  display: block;
}

.list-lessons-title {
  color: $list-link-color;
  .h5 {
    @include media-breakpoint-down(sm) {
      font-size: 0.93rem;
    }
  }
}

.list-lessons-info {
  position: relative;
}

.list-lessons-status {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
}

.list-lessons-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 15px;

  .icon {
    top: 2px !important;
    right: 0 !important;
    transform: translate(0) !important;
  }
}
