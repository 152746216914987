.list-group {
    .list-group-item {
        padding: .7rem 1rem;
        color: $body-color;
        position: relative;
        border-color: $list-border;        

        &.active {
            background-color: $white;
            font-weight: 500;
        }

        &:first-child {
            border-radius: 7px 7px 0 0;
        } 
        
        &:last-child {
            border-radius: 0 0 7px 7px; 
        } 

        &:first-child {
            border-radius: 7px 7px 0 0;
            &:last-child {
                border-radius: 7px !important;
            } 
        } 

        &.disabled {
            color:  $list-disabled-color;
        }

        // Icon
        .icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 12px;
        } 
        
        & + .list-group {
           .list-group-item {
            &:first-child {
                border-radius:0 !important;
                &:last-child {
                    border-radius: 0 0 7px 7px !important;
                }
            } 
           } 
        }
    }
    .row-selection{
        background-color:#96dff5;
    }
    
    .coach-trainee-popup{
        margin-top: 190px !important;
    }

    .list-table-item{
        min-height: 100px;
    }

    .list-table-item:hover{
        .button-show-hide{
            display: block;
        }
        .pending-btn{
            display: none;
        }
    }

    .button-show-hide{
        display: none;
    }
    .pending-btn{
        color:#ff0000;
        background-color: #e8d3d3;
        border-color: #b0b8c2;
    }


    // Title
    .list-group-item-title {
        display: block;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $list-color;
    }

    .list-group-item-action {
        &:focus {
            background: white;
        }
    }
}

