.float-button {
  display: inline-block;
  position: relative; 
  cursor: pointer;
  display: flex;
  align-items: center;  

  &-text {
    color: $link-color;
    margin-left: 6px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .75rem;
    position: relative;
    left: -10px;
    opacity: 0;
    transition: .1s;
  }

  &-icon {
    position: relative;
    //color: $text-muted;
    font-size: .85rem;
    display: inline-block;
    height: 23px;
    width: 23px;
    border: 1px solid $border-color-dark;
    border-radius: 50%;
    transition: .3s;

    & > i {
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .float-button-icon {
      color: $link-color;
    }

    .float-button-text {
      left: 0;
      opacity: 1;
      color: $link-color;
    }
  }
}