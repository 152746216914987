// Lessons List
// It's used to list all the lessons 

.lessons-builder-list {
  list-style: none;
  padding-left: 0;   
  
  // Item
  &-item {    
    margin-top: 20px; 

    // &[draggable] {
    //   .lessons-builder-lesson-container {
    //     box-shadow: 0 0 20px rgba(#000, .1);
    //     transition: box-shadow .4s;
    //   }
    // }
  }

  // Drag Drop Icon
  .drag-drop-icon {
    display: block;
    width: 18px;
    height: 40px;
    color: $gray-400;
    font-size: 22px;

    & > * {
      margin: 0 1px;
    }
  }
}